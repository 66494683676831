<template>
<div class="col-12">
<div class="col-6 float-start" style="padding:1%;">
<b>フレームの幅</b> <input class="form-control" type="text" v-model="w"  ><br>
</div>
<div class="col-6 float-end" style="padding:1%;">
<b>フレームの高さ</b> <input  class="form-control" type="text" v-model="h" >
</div>
</div>

<hr>
<JSField ref="jsfield" :path="path" >
</JSField>
<hr>
<button class="btn btn-dark" v-on:click="updateScript" > 実行 </button>
<br>

<div style="height:200px;">
   <br>
     <b>Javascript</b>

       <MonacoEditor 
        class="editor" 
        ref="scriptEditor"
        :options="jsoption"
        v-model="jstext"
        language="javascript" 
        @change="jsEditorDidChange"
       >
       </MonacoEditor>
    
  </div>
  <br><br>
 <div style="height:200px;">
  <b> SQL</b>
       <MonacoEditor 
        class="editor" 
        ref="editor"
        :options="option"
        v-model="text"
        language="sql" 
        @change="editorDidChange"
       >
       </MonacoEditor>
       </div>
<br>
  
</template>

<script>

import MonacoEditor from 'monaco-editor-vue3'

import JSField from "../../components/chart/JSField.vue";

export default {
  name: 'JSFieldView',
  components:  { JSField,MonacoEditor } ,
  data(){  return {
        path : "" ,
        text : "" , 
        w : "100%" , 
        h : "300px" , 
        jstext : "" ,
        option : {value:this.text},
        jsoption : {value:this.jstext}  
        };
    },
  async created( ) {
      
      this.path = window.location.href;   
      const param = new URLSearchParams(window.location.search);
       const q = param.get('q');
       const s = param.get('s');
      
       if(q){
       this.text = q ;
       this.option.text = q ;
       }
       if(s){
       this.jstext = s ;
       this.option.jstext = s ;
       }
               },
    methods: {
        editorDidChange(text){     
            this.text  = text;  
        }, 
        jsEditorDidChange(text){     
            this.jstext  = text;  
        }, 
       async updateScript (){
        
        const q = this.text.replaceAll('+','%2B').replaceAll('?','%3F');

        const s = this.jstext.replaceAll('&', '%26').replaceAll('?','%3F').replaceAll('+','%2B').replace(/var /g,'\nvar ').replace(/let /g,'\nlet').replace(/const /g,'\nconst ').replace(/; /g,'\n; ');
        
          const path = window.location.href + "&q="+q + "&s="+s + "&w="+this.w + "&h="+this.h  ;
            if ( window.parent !== window && !window.isCrossOrigin ) { 
          
               window.parent.history.pushState( {}, '', new URL( window.parent.location.href).pathname + "?q="+ q.replaceAll ( '+','%2B' )+"&s="+ s.replaceAll ( '+','%2B' )+"&w="+ this.w +"&h="+ this.h ) ;  
            }
           this.$refs.jsfield.showData(window.parent.Sql,path );
        }
    }
}

</script>

<style>
main{
width: 100% !important ;
}

.container {
  width: 200px;
  margin: 30px;
}

.input {
  width: 100%;
  margin: 0px;
  padding-right: 5px;
}</style>